import React, { useEffect } from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Helmet } from 'react-helmet-async';
import { useLocation, Outlet } from 'react-router-dom';
import { pageview } from './utils/analytics';

export default function App() {
  const location = useLocation();

  useEffect(() => {
    // Track page views whenever the location changes
    try {
      pageview(location.pathname + location.search);
    } catch (error) {
      console.warn('Analytics error:', error);
    }
  }, [location]);

  const schemaOrgData = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'Marc Plotkin',
    description: 'Grammy-Shortlisted Producer, Songwriter, Singer, Sax & Guitar Player',
    url: 'https://marcplotkin.com',
    image: '/images/marc-plotkin.jpg',
    sameAs: [
      'https://www.instagram.com/marcplotkin',
      'https://www.youtube.com/user/marcplotkin',
      'https://www.tiktok.com/@plotkin',
      'https://twitter.com/marcplotkin',
      'https://www.linkedin.com/in/marcplotkin/'
    ],
    jobTitle: ['Music Producer', 'Entrepreneur', 'Professor'],
    affiliation: {
      '@type': 'Organization',
      name: "NYU's Clive Davis Institute"
    },
    hasOccupation: [
      {
        '@type': 'Occupation',
        name: 'Music Producer',
        description: 'Grammy-Shortlisted Producer',
        skills: ['Music Production', 'Songwriting', 'Saxophone', 'Guitar', 'Vocals'],
        occupationLocation: { '@type': 'City', name: 'New York' }
      },
      {
        '@type': 'Occupation',
        name: 'Professor',
        description: 'Professor at NYU\'s Clive Davis Institute',
        skills: ['Music Education', 'Music Industry', 'Music Technology'],
        occupationLocation: { '@type': 'City', name: 'New York' }
      },
      {
        '@type': 'Occupation',
        name: 'Entrepreneur',
        description: 'Founder/CEO of Beast Music A.I.',
        skills: ['Artificial Intelligence', 'Music Technology', 'Business Development'],
        occupationLocation: { '@type': 'City', name: 'New York' }
      }
    ],
    knowsAbout: [
      'Music Production',
      'Songwriting',
      'Saxophone Performance',
      'Guitar Performance',
      'Vocal Performance',
      'Music Education',
      'Music Technology',
      'Artificial Intelligence',
      'Entrepreneurship'
    ],
    performerIn: [
      {
        '@type': 'MusicGroup',
        name: 'Session Musician',
        description: 'Professional session work as saxophonist and guitarist'
      }
    ],
    award: [
      'Grammy Shortlist',
      'Bloomberg BizWeek Top 25 Entrepreneur'
    ]
  };

  return (
    <>
      <Helmet>
        <title>Marc Plotkin | Official Site</title>
        <meta name="description" content="Grammy-Shortlisted Producer, Songwriter, Singer, Sax & Guitar Player" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Marc Plotkin | Official Site" />
        <meta property="og:description" content="Grammy-Shortlisted Producer, Songwriter, Singer, Sax & Guitar Player" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/marc-plotkin.jpg" />
        
        {/* Schema.org JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgData)}
        </script>
      </Helmet>
      
      <div className="relative min-h-screen overflow-hidden">
        <div className="fixed inset-0 bg-gradient-to-r from-background-dark via-purple-900 to-orange-900 animate-gradient-x" />
        
        <div className="relative z-10">
          <Header />
          <main className="container mx-auto px-4 sm:px-6">
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}