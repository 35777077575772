import React from 'react';
import {
  Instagram,
  Youtube,
  Twitter,
  Facebook,
  Linkedin,
  Music2,
} from 'lucide-react';

export function Footer() {
  return (
    <footer 
      className="container mx-auto px-4 sm:px-6 py-8 sm:py-12 text-primary text-center"
      itemScope
      itemType="http://schema.org/WPFooter"
      role="contentinfo"
      data-semantic-type="professional-footer"
    >
      <p 
        className="mb-6 sm:mb-8 text-sm sm:text-base px-4"
        itemProp="description"
        data-content-type="featured-work"
      >
        <a
          href="https://youtu.be/vmVaCbxkd34"
          target="_blank"
          rel="noopener noreferrer"
          className="text-accent hover:text-accent-hover transition-all duration-300 hover:scale-110 transform inline-block underline decoration-2 underline-offset-4 break-words"
          itemProp="url"
          data-link-type="featured-music"
        >
          "this song explains why i'm leaving home to become a stewardess..."
        </a>
      </p>

      <div 
        className="flex flex-wrap justify-center gap-4 sm:gap-6"
        itemScope
        itemType="http://schema.org/Person"
        data-content-type="social-profiles"
      >
        <SocialLink 
          href="https://www.instagram.com/marcplotkin" 
          Icon={Instagram} 
          platform="Instagram"
          data-profile-type="social"
        />
        <SocialLink 
          href="https://www.youtube.com/user/marcplotkin" 
          Icon={Youtube} 
          platform="YouTube"
          data-profile-type="music"
        />
        <SocialLink 
          href="https://www.tiktok.com/@plotkin" 
          Icon={Music2} 
          platform="TikTok"
          data-profile-type="social-music"
        />
        <SocialLink 
          href="https://twitter.com/marcplotkin" 
          Icon={Twitter} 
          platform="Twitter"
          data-profile-type="social"
        />
        <SocialLink 
          href="https://www.facebook.com/plotkinmarc" 
          Icon={Facebook} 
          platform="Facebook"
          data-profile-type="social"
        />
        <SocialLink 
          href="https://www.linkedin.com/in/marcplotkin/" 
          Icon={Linkedin} 
          platform="LinkedIn"
          data-profile-type="professional"
        />
      </div>
    </footer>
  );
}

interface SocialLinkProps {
  href: string;
  Icon: React.ComponentType<{ className?: string }>;
  platform: string;
  'data-profile-type': 'social' | 'music' | 'professional' | 'social-music';
}

function SocialLink({ href, Icon, platform, ...props }: SocialLinkProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-accent hover:text-accent-hover transition-all duration-300 hover:scale-110"
      itemProp="sameAs"
      aria-label={`Visit Marc Plotkin on ${platform}`}
      {...props}
    >
      <Icon className="w-6 h-6 sm:w-8 sm:h-8" aria-hidden="true" />
    </a>
  );
}