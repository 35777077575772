import React, { useRef, useState, useEffect } from 'react';
import { event as analyticsEvent } from '../utils/analytics';

export const Sax: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const progressCheckpoints = useRef<Set<number>>(new Set([25, 50, 75, 90, 100]));
  const viewSent = useRef(false);
  const lastPlayPosition = useRef<number>(0);
  const isUserPaused = useRef(false);

  // Format seconds to MM:SS format
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // Track video load
    const handleLoadedData = () => {
      if (!viewSent.current) {
        analyticsEvent({
          action: 'video_loaded',
          category: 'Video',
          label: 'Sax Reel'
        });
        viewSent.current = true;
      }
    };

    // Track play event
    const handlePlay = () => {
      if (!hasStartedPlaying) {
        analyticsEvent({
          action: 'video_started',
          category: 'Video',
          label: 'Sax Reel'
        });
        setHasStartedPlaying(true);
      }
      isUserPaused.current = false;
    };

    // Track progress
    const handleTimeUpdate = () => {
      if (!video) return;
      
      // Update last play position
      lastPlayPosition.current = video.currentTime;
      
      const percentComplete = Math.floor((video.currentTime / video.duration) * 100);
      
      // Check if we've hit any of our progress checkpoints
      progressCheckpoints.current.forEach(checkpoint => {
        if (percentComplete >= checkpoint) {
          analyticsEvent({
            action: 'video_progress',
            category: 'Video',
            label: `Sax Reel - ${checkpoint}%`,
            value: checkpoint
          });
          // Remove this checkpoint so we don't track it again
          progressCheckpoints.current.delete(checkpoint);
        }
      });
    };

    // Track pause events to capture when users stop watching
    const handlePause = () => {
      // Only track pauses initiated by the user, not by other means (like video ended)
      if (!video.ended && hasStartedPlaying) {
        isUserPaused.current = true;
        const timestamp = formatTime(lastPlayPosition.current);
        analyticsEvent({
          action: 'video_stopped_at',
          category: 'Video',
          label: `Sax Reel - Stopped at ${timestamp}`,
          value: Math.floor(lastPlayPosition.current)
        });
      }
    };

    // Track video abandonment on page unload
    const handleBeforeUnload = () => {
      if (hasStartedPlaying && !video.ended && !isUserPaused.current) {
        const timestamp = formatTime(lastPlayPosition.current);
        analyticsEvent({
          action: 'video_abandoned',
          category: 'Video',
          label: `Sax Reel - Abandoned at ${timestamp}`,
          value: Math.floor(lastPlayPosition.current)
        });
      }
    };

    // Track video completion
    const handleEnded = () => {
      analyticsEvent({
        action: 'video_completed',
        category: 'Video',
        label: 'Sax Reel'
      });
    };

    // Add event listeners
    video.addEventListener('loadeddata', handleLoadedData);
    video.addEventListener('play', handlePlay);
    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('pause', handlePause);
    video.addEventListener('ended', handleEnded);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup
    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('ended', handleEnded);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasStartedPlaying]);

  const handleVideoClick = () => {
    if (videoRef.current) {
      // Toggle mute state
      videoRef.current.muted = false;
      
      // Track unmute event
      if (isMuted) {
        analyticsEvent({
          action: 'video_unmuted',
          category: 'Video',
          label: 'Sax Reel'
        });
      }
      
      setIsMuted(false);
    }
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-2 sm:py-4">
      <div className="max-w-xl sm:max-w-2xl mx-auto space-y-2 sm:space-y-3 md:space-y-4">
        {/* Saxophone emoji above the text box */}
        <div className="text-center">
          <span className="text-4xl sm:text-5xl md:text-6xl floating-text text-accent not-italic inline-block">🎷</span>
        </div>
        
        {/* Introduction text box */}
        <div className="backdrop-blur-lg bg-background-card rounded-[1rem] sm:rounded-[1.5rem] p-3 sm:p-4 md:p-5 text-center hover:shadow-lg hover:shadow-accent/10 transition-all duration-300">
          <p className="text-base sm:text-lg md:text-xl font-normal text-primary">
            People often ask for samples of my sax playing. I could send them links to a hundred songs/videos, but I made this reel instead—one link, all sax, zero scavenger hunts. Enjoy!
          </p>
        </div>
        
        {/* Video section */}
        <div className="relative rounded-lg overflow-hidden shadow-xl">
          <video 
            ref={videoRef}
            controls
            playsInline
            autoPlay
            muted
            className="w-full"
            onClick={handleVideoClick}
          >
            <source src="/videos/2023.4.14 - Sax Reel.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          {isMuted && (
            <div 
              className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer"
              onClick={handleVideoClick}
            >
              <div className="bg-black bg-opacity-60 rounded-full p-3 sm:p-4 md:p-5 animate-pulse">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={1.5} 
                  stroke="white" 
                  className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                </svg>
              </div>
              <p className="text-white font-bold mt-2 sm:mt-3 bg-black bg-opacity-60 px-3 py-1 sm:px-4 sm:py-2 rounded-full text-sm sm:text-base">
                Click to unmute
              </p>
            </div>
          )}
        </div>
        
        {/* Contact text box */}
        <div className="backdrop-blur-lg bg-background-card rounded-[1rem] sm:rounded-[1.5rem] p-3 sm:p-4 md:p-5 text-center hover:shadow-lg hover:shadow-accent/10 transition-all duration-300">
          <p className="text-base sm:text-lg md:text-xl font-normal text-primary">
            p.s. <a href="mailto:marc@marcplotkin.com" className="text-accent hover:text-accent-hover underline">hit me up</a> if you want some horn on your song or show <span className="not-italic">😉</span>
          </p>
        </div>
      </div>
    </div>
  );
}; 