import { useState } from 'react';
import { Arrow } from './Arrow';

export const Decisions = () => {
  const [currentFocus, setCurrentFocus] = useState<number>(0);

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setCurrentFocus(prev => Math.min(prev + 1, decisions.length - 1));
        break;
      case 'ArrowUp':
        event.preventDefault();
        setCurrentFocus(prev => Math.max(prev - 1, 0));
        break;
      case 'Enter':
      case ' ':
        event.preventDefault();
        // Trigger the decision action
        break;
    }
  };

  return (
    <div 
      className="relative min-h-screen overflow-hidden"
      role="main"
      aria-label="Decision Making Flow"
    >
      <div className="fixed inset-0 bg-gradient-to-r from-background-dark via-purple-900 to-orange-900 animate-gradient-x" />
      
      <div className="relative z-10 py-12 container mx-auto px-4">
        <div 
          className="max-w-4xl mx-auto text-center backdrop-blur-lg bg-background-card rounded-[1.5rem] sm:rounded-[2rem] p-8"
          role="region"
          aria-label="Decision Steps"
        >
          <h1 
            className="text-2xl sm:text-3xl text-white mb-8"
            tabIndex={0}
          >
            define, eliminate, automate, delegate, execute
          </h1>

          <div 
            className="space-y-8"
            role="list"
            aria-label="Decision Questions"
          >
            {[
              {
                question: "do you understand what this is?",
                leftEnd: false,
                leftStep: "yes",
                rightEnd: true,
                rightStep: "no",
                rightContent: "slow down and make yourself understand it.",
                accentRight: true
              },
              {
                question: <>is it essential and/or something you <span className="font-bold">really</span> want to do?</>,
                leftEnd: false,
                leftStep: "yes",
                rightEnd: true,
                rightStep: "no",
                rightContent: "remove yourself from it.",
                accentRight: true
              }
            ].map((decision, index) => (
              <div 
                key={index}
                role="listitem"
                tabIndex={0}
                onKeyDown={(e) => handleKeyDown(e, index)}
                aria-current={currentFocus === index}
                className={`focus:outline-none focus:ring-2 focus:ring-accent rounded-lg p-2 ${
                  currentFocus === index ? 'bg-background-dark/20' : ''
                }`}
              >
                <div 
                  className="text-lg sm:text-xl mb-4"
                  role="heading"
                  aria-level={2}
                >
                  {decision.question}
                </div>
                <div 
                  className="flex justify-center gap-4 sm:gap-6"
                  role="group"
                  aria-label="Decision Options"
                >
                  <button
                    className="px-4 py-2 rounded-full bg-accent hover:bg-accent-hover transition-colors"
                    aria-label={`Select ${decision.leftStep}`}
                  >
                    {decision.leftStep}
                  </button>
                  <button
                    className="px-4 py-2 rounded-full bg-accent hover:bg-accent-hover transition-colors"
                    aria-label={`Select ${decision.rightStep}`}
                  >
                    {decision.rightStep}
                  </button>
                </div>
                {decision.rightContent && (
                  <div 
                    className="mt-4 text-sm text-accent-hover"
                    role="alert"
                    aria-live="polite"
                  >
                    {decision.rightContent}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}