import React from 'react';
import { type RouteObject } from 'react-router-dom';
import App from '../App';
import ErrorPage from '../components/ErrorPage';
import Home from '../components/Home';
import { Decisions } from '../components/Decisions';
import { Sax } from '../components/Sax';

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
        handle: {
          title: "Marc Plotkin | Official Site",
          description: "Grammy-Shortlisted Producer, Songwriter, Singer, Sax & Guitar Player"
        }
      },
      {
        path: "decisions",
        element: <Decisions />,
        handle: {
          title: "Decision Making Flow | Marc Plotkin",
          description: "A framework for making better decisions"
        }
      },
      {
        path: "sax",
        element: <Sax />,
        handle: {
          title: "Marc Plotkin | Sax Reel",
          description: "Saxophone performances and recordings by Marc Plotkin"
        }
      }
    ]
  }
]; 