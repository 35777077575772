import { config } from '../config';

// Type definitions for Google Analytics
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

/**
 * Tracks page views
 */
export const pageview = (url: string): void => {
  try {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
      window.gtag('config', process.env.VITE_GA_ID || import.meta.env.VITE_GA_ID || '', {
        page_path: url,
        page_location: window.location.href,
        page_title: document.title
      });
    }
  } catch (error) {
    console.warn('Analytics error:', error);
  }
};

/**
 * Tracks custom events
 */
export const event = ({ action, category, label, value }: {
  action: string;
  category: string;
  label?: string;
  value?: number;
}): void => {
  try {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
      });
    }
  } catch (error) {
    console.warn('Analytics event error:', error);
  }
}; 