import React from 'react';
import { Link } from 'react-router-dom';

export function Header() {
  return (
    <header 
      className="min-h-[10vh] flex flex-col items-center justify-center px-4 sm:px-6 text-primary mb-4"
      itemScope
      itemType="http://schema.org/WPHeader"
      role="banner"
      aria-label="Site Header"
    >
      <h1 
        className="text-2xl sm:text-4xl md:text-6xl font-bold font-display text-center floating-text"
        itemProp="headline"
        data-content-importance="high"
        data-semantic-type="main-title"
      >
        <span>marc plotkin...</span>
        <Link to="/" className="hover:text-primary-hover transition-colors duration-300">
          <span>what's his deal?</span>
        </Link>
      </h1>
    </header>
  );
}