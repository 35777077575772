import React from 'react';
import { useRouteError, useNavigate, isRouteErrorResponse } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  
  let errorMessage = 'An unexpected error occurred';
  let status = 500;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText || error.data?.message || errorMessage;
    status = error.status;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-background-dark via-purple-900 to-orange-900">
      <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
        <div className="backdrop-blur-lg bg-background-card rounded-[1.5rem] p-8 max-w-lg w-full">
          <h1 className="text-4xl font-bold text-accent mb-4">
            Application Error
          </h1>
          <p className="text-xl mb-4 text-white">
            {errorMessage}
          </p>
          <p className="text-lg text-gray-400 mb-8">
            Status: {status}
          </p>
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-accent hover:bg-accent-hover text-white rounded-full transition-colors duration-300"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
}